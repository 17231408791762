import React from 'react'

import {Link} from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'
import Footer from '../../components/footer'

import '../../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'
import {responsive} from '../../utils/responsive'

const PrivacyPage = () => {
    return (<Layout>
        <SEO title="Privacy Policy"/>
        <Banner class_suffix="deep"/>
        <div className="about-container">
            <div className="about-content">
                <header>
                    <h1>Privacy Policy</h1>
                    <p><strong>Last updated:</strong> November 11, 2024</p>
                </header>

                <section>
                    <h2>1. Identity and Contact Information of the Data Controller</h2>
                    <p><strong>Organization Name:</strong> PolyCyber</p>
                    <p><strong>Contact Email:</strong> <a href="mailto:dpo@polycyber.io">dpo@polycyber.io</a></p>
                </section>

                <section>
                    <h2>2. Personal Data Collected</h2>
                    <p>The Organization may collect the following categories of personal data:</p>
                    <ul>
                        <li><strong>Identification Data:</strong> name, surname</li>
                        <li><strong>Contact Information:</strong> email address, phone number</li>
                        <li><strong>Professional Data:</strong> CV, cover letter</li>
                    </ul>
                </section>

                <section>
                    <h2>3. Purposes of Data Processing</h2>
                    <p>Your personal data is collected and processed for the following purposes:</p>
                    <ul>
                        <li>Manage participants' applications (e.g., CV analysis);</li>
                        <li>Organize selection processes or events related to the Organization's activities;</li>
                    </ul>
                    <p>Any other use will be subject to your explicit consent.</p>
                </section>

                <section>
                    <h2>4. Legal Basis for Processing</h2>
                    <p>The Organization processes your data based on the following legal grounds:</p>
                    <ul>
                        <li><strong>Consent:</strong> for processing activities requiring prior authorization, such as
                            sending marketing communications.
                        </li>
                        <li><strong>Legitimate Interest:</strong> to improve our selection and recruitment processes.
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>5. Data Recipients</h2>
                    <p>Your personal data is accessible only to authorized personnel within the Organization and, if
                        necessary:</p>
                    <ul>
                        <li>To sponsors involved in evaluating applications or organizing events;</li>
                    </ul>
                </section>

                <section>
                    <h2>6. Data Retention Period</h2>
                    <p>Your personal data will be retained:</p>
                    <ul>
                        <li>For CVs and applications: one year.</li>
                        <li>For purposes based on consent: until consent is withdrawn.</li>
                    </ul>
                </section>

                <section>
                    <h2>7. Your Rights</h2>
                    <p>Under Law 25, you have the following rights concerning your data:</p>
                    <ul>
                        <li><strong>Access:</strong> to obtain a copy of your processed personal data;</li>
                        <li><strong>Rectification:</strong> to correct inaccurate or incomplete data;</li>
                        <li><strong>Erasure:</strong> to request the deletion of your data, subject to certain
                            conditions;
                        </li>
                        <li><strong>Objection:</strong> to object to the processing of your data;</li>
                    </ul>
                    <p>To exercise these rights, contact us at: <a href="mailto:dpo@polycyber.io">dpo@polycyber.io</a>.
                    </p>
                </section>

                <section>
                    <h2>8. Data Security</h2>
                    <p>The Organization implements technical and organizational measures to protect your data from
                        unauthorized access, loss, destruction, or accidental disclosure. This includes:</p>
                    <ul>
                        <li>Securing IT systems and servers;</li>
                        <li>Strict access controls for authorized personnel;</li>
                        <li>Encryption or pseudonymization of sensitive data.</li>
                    </ul>
                </section>

                <section>
                    <h2>9. Changes to the Privacy Policy</h2>
                    <p>The Organization reserves the right to modify this policy at any time to reflect legal,
                        regulatory, or technical developments. Any updates will be published on <a
                            href="http://polycyber.io">polycyber.io</a>.</p>
                </section>

                <section>
                    <h2>10. Contact and Complaints</h2>
                    <p>If you have any questions about this policy, you can contact us at:</p>
                    <p><strong>Email address:</strong> <a href="mailto:dpo@polycyber.io">dpo@polycyber.io</a></p>
                    <p>If you disagree, you have the right to file a complaint with the competent supervisory
                        authority:</p>
                    <p><strong>CAI</strong> (Quebec): <a href="https://www.cai.gouv.qc.ca">cai.gouv.qc.ca</a>.</p>
                </section>

            </div>
        </div>
    </Layout>)
}
export default PrivacyPage
